<script setup>
import '@kangc/v-md-editor/lib/style/base-editor.css';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import VueMarkdownEditor from '@kangc/v-md-editor';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import Prism from 'prismjs';
import {defineProps, onMounted, ref} from "vue";
import axios from "axios";
import {apiBaseUrl} from "@/config";
import {upload_article_img} from "@/common-api";
import Cookies from "js-cookie";
import {Snackbar} from "@varlet/ui";
const token = Cookies.get('N_token');

  VueMarkdownEditor.use(vuepressTheme, {
      Prism,
  });

  const props = defineProps({
    article_id: String
  })

  const WritingText = ref('')
  const FormArticle = ref({
    id: null,
    title: '',
    author: '小诺',
    is_visible: true,
    cover_img: '',
    describe: '',
    content: '',
  })


  const Editor_save = (text) => {
     // 保存文章
    FormArticle.value.content = text;
    let request_url = `${apiBaseUrl}/admin/submit_article`
    if (FormArticle.value.id){
      if (confirm("确认保存修改吗？")){
        request_url = `${apiBaseUrl}/admin/update_article`
      }
      else{
        return
      }
    }
    axios.post(request_url, FormArticle.value, {headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          }})
        .then((response)=>{
          FormArticle.value.id = response.data.id;  // 保存成功
          Snackbar({
            content: response.data.message,
            type: "success"
          });
        })
        .catch((error)=>{
          Snackbar({
            content: error.response.data.detail || error,
            type: "error"
          })
        })
  }

  const Upload_img = async (event, insertImage, files) => {
    const formData = new FormData();
    formData.append('img_file', files[0]);
    upload_article_img(files[0])
        .then(response=>{
          const file_name = response.data.file_name;
          WritingText.value += `\n![${file_name}](/get_img/article/${file_name})\n`;
        })
        .catch(error=>{
          Snackbar({
            content: error.response.data.detail,
            type: "success"
          });
        }
    );
	};

  const Sele_cover_img = (event)=>{
    upload_article_img(event.target.files[0])
        .then(response=>{
          FormArticle.value.cover_img = response.data.file_name;
        })
        .catch(error=>{
          Snackbar({
            content: error.response.data.detail || error,
            type: "error"
          });
        })
  }

  onMounted(()=>{
    // 如果有id值，则获取旧文章
    if (props.article_id){
      FormArticle.value.id = props.article_id;  // 添加ID值
      axios.get(`${apiBaseUrl}/article/${props.article_id}`)
          .then((response)=>{
            const article_info = response.data.data
            FormArticle.value.id = article_info.id;
            FormArticle.value.title = article_info.title;
            FormArticle.value.author = article_info.author;
            FormArticle.value.is_visible = article_info.is_visible;
            FormArticle.value.cover_img = article_info.cover_img;
            FormArticle.value.describe = article_info.describe;
            WritingText.value = article_info.content;

          })
          .catch((error)=>{
            Snackbar({
              content: error.response.data.detail,
              type: "error"
            });
          })
    }
  })
</script>

<template>
  <form class="row">
    <div class="col-7 row">
      <div class="nes-field col-6">
        <label>文章标题：</label>
        <input type="text" class="nes-input" v-model="FormArticle.title" required/>
      </div>
      <div class="nes-field col-6">
        <label for="inline_field">作者：</label>
        <input type="text" id="inline_field" class="nes-input" v-model="FormArticle.author" required/>
      </div>
      <div class="nes-field">
        <label>文章描述：</label>
        <textarea class="nes-input" placeholder="文章描述" v-model="FormArticle.describe" required>
        </textarea>
      </div>

      <div class="nes-field">
        <label>封面图片</label>
        <input type="file" @change="Sele_cover_img" accept="image/gif, image/jpeg" required/>
      </div>
      <label>
        <input type="checkbox" class="nes-checkbox"
               v-model="FormArticle.is_visible" checked/>
        <span>文章是否可见</span>
      </label>
    </div>
    <div class="col">
      <img :src="[FormArticle.cover_img ? '/get_img/article/' + FormArticle.cover_img :   '']" class="img-fluid"
           :alt="[FormArticle.cover_img ? FormArticle.cover_img : '未选择封页']">
    </div>
  </form>
  <VueMarkdownEditor v-model="WritingText"
                     :disabled-menus="[]"
                     @save="Editor_save"
                     @upload-image="Upload_img"
      class="h-100 w-100">
  </VueMarkdownEditor>
</template>

<style scoped>

</style>