import { createApp } from 'vue'
import App from './App.vue'
import {router} from '@/route'
import Varlet, {Snackbar} from '@varlet/ui'
import 'bootstrap/dist/js/bootstrap.min'
import '@varlet/touch-emulator'
import '@varlet/ui/es/style'

window.addEventListener('hashchange', () => {
    const hash = location.hash.replace('#', '');
    const element = document.getElementById(hash);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
    }
});

// Varlet组件配置
Snackbar.allowMultiple(true)


const app = createApp(App)
app.use(router)
app.use(Varlet)
app.mount('body')