<script setup>
import {onBeforeMount, ref} from "vue";
import axios from "axios";
import {apiBaseUrl} from "@/config";
import {Snackbar} from "@varlet/ui";
import Cookies from "js-cookie";
const token = Cookies.get('N_token');

const total_moments = ref()
let moment_table_columns;

const handleDelMoment = (the_id)=>{
  const formData = new FormData()
  formData.append("del_id", the_id)
  const decide = confirm(`确定删除《${the_id}》吗？`)
  if (decide){
  axios.post(`${apiBaseUrl}/admin/del_moment`,
      formData,
      {
        headers: {
            Authorization: `Bearer ${token}`,
          },
      })
      .then((response)=>{
        Snackbar({
            content: response.data.message,
            type: "success"
          })
        get_moments();
    })
      .catch((error)=>{
        Snackbar({
          content: error.response.data.detail || error,
          type: "error"
        })
      })
  }
}
const get_moments = ()=>{
  axios.get(`${apiBaseUrl}/moment/get_moments?`+ new URLSearchParams({year: 'all', page: 0}),
      {
        params: {
          if_group_year: false
        }
      }
  )
      .then((response)=>{
        total_moments.value = response.data.data
        moment_table_columns = Object.keys(total_moments.value[0])
      })
      .catch((error)=>{
        Snackbar({
          content: error.response.data.detail || error,
          type: "error"
        });
      });
}
onBeforeMount(()=>{
  get_moments();
})
</script>

<template>
  <div>
    <div class="action">
      <router-link to="./new">新增说说</router-link>
    </div>

    <var-table>
      <thead>
        <tr>
          <th v-for="col in moment_table_columns" :key="col"><h4>{{ col }}</h4></th>
          <th><h4>操作</h4></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="moment in total_moments" :key="moment.id">
          <td v-for="col in moment_table_columns" :key="col">
            <var-ellipsis style="max-width: 14vw">
              {{ moment[col] }}
            </var-ellipsis>
          </td>
          <td class="action" >
            <router-link :to="'./' + moment.id">编辑</router-link>
            <a @click="handleDelMoment(moment.id)">删除</a>
          </td>
        </tr>
      </tbody>
    </var-table>
  </div>
</template>

<style scoped>
</style>