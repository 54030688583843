<script setup>
  import HeaderHobby from "@/components/HeaderHobby.vue";
  import CommentSection from "@/components/CommentSection.vue";
  import NewArticles from "@/components/SideCardParts/NewArticles.vue";
  import MineInfo from "@/components/SideCardParts/MineInfo.vue";
  const page_id = "084e0343a0486ff05530df6c705c8bb4"
</script>

<template>
  <HeaderHobby/>
  <main>
    <div class='container-xxl bg-N-blue' style="height: 22px; border-radius: 11px"></div>

    <div class="row container-xl">
      <div class="main-contain col">
        <!--   前言   -->
        <div>
          <h3>欢迎来到留言区</h3>
          <p>✅可以写什么呢？ 比如对本站的建议，有什么体验需要优化的，有什么问题也可以在此处发言。当然，也可以说说日常，吐槽吐槽，安利分享，都是可以的。</p>
          <br/>
        </div>
        <CommentSection :page_id="page_id"/>
      </div>

      <div class="side-guider col-4 d-none d-lg-block" id="side-guider">
        <MineInfo/>

        <div class="side-sticky">
          <NewArticles/>
        </div>
      </div>
    </div>
  </main>
</template>
<style scoped>
  .main-contain{
    padding: 4rem;
    font-family: PixelFont, serif;
    font-weight: bolder;
  }

</style>