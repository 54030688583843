<script setup>
import {onMounted, ref} from "vue";
import {apiBaseUrl} from "@/config";
import axios from "axios";

  const new_articles = ref([])
  onMounted(()=>{
    axios.get(`${apiBaseUrl}/article/news`).then((response)=>{
      new_articles.value = response.data.data
    }).catch()
  })
</script>

<template>
  <div>
    <h4>最新文章</h4>
    <ul>
      <li v-for="article in new_articles" :key="article.id" class="row" style="margin-top: .5rem">
        <a :href="'/article/'+article.id" class="col-auto art-img">
          <var-avatar :src="'/get_img/article/' + article.cover_img" :size="90" :round="false"/>
        </a>
        <div class="art-contain col">
          <a :href="'/article/'+article.id" class="art-title text-N-blue">{{ article.title }}</a>
          <br>
          <time :title="'更新于' + article.last_update_time.replace('T', ' ')">{{ article.last_update_time.replace('T', ' ') }}</time>
          <var-ellipsis style="max-width: 100%" :line-clamp="2">
            {{ article.describe }}
          </var-ellipsis>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>
  .art-img, .art-contain {
    padding: 0;
  }
  .art-contain{
    padding-left: .5rem;
  }
  .art-contain .art-title{
    margin-bottom: 0;
    font-size: 1.2rem;
  }
  .art-contain time{
    color: gray;
  }
</style>