<script setup>
  import HeaderHobby from "@/components/HeaderHobby.vue";
  import axios from "axios";
  import {apiBaseUrl} from "@/config";
  import {onMounted, ref} from "vue";
  import '@kangc/v-md-editor/lib/theme/style/github.css';
  import MineInfo from "@/components/SideCardParts/MineInfo.vue";
  import NewArticles from "@/components/SideCardParts/NewArticles.vue";

  const articles = ref();  // 所有文章信息
  onMounted(()=>{
    axios.get(`${apiBaseUrl}/article/get_articles`).then((response)=>{
      articles.value = response.data.data
    });
  })

</script>

<template>
  <HeaderHobby></HeaderHobby>
  <main>
    <div class='container-xxl bg-N-orange' style="height: 22px; border-radius: 11px"></div>

    <div class="row container-xl">
      <div class="main-contain col-md-12 col-lg">
          <!--     文章列表     -->
        <div class="article-list">
            <div class="card" v-for="(article, index) in articles" :key="index">
              <div class="row row-0">
                <div :class="['col-4', { 'order-md-last': index % 2 !== 0 }]">
                  <img :src="'/get_img/article/' + article.cover_img" :alt="article.cover_img"
                       class="w-100 h-100 object-cover">
                </div>
                <div class="col">
                  <div class="card-body">
                    <router-link :to="'./' + article.id">
                      <h3 class="card-title text-N-blue">
                        {{ article.title }}
                      </h3>
                    </router-link>
                    <p style="color: darkgrey">
                      <span>{{ article.author }}</span> <time>于 {{ article.create_time.replace('T', ' ') }} 发表</time>
                    </p>
                    <p>{{ article.describe }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

      <!--   侧边栏   -->
      <div class="side-guider col-4 d-none d-lg-block">
        <MineInfo/>
        <div class="side-sticky">
          <NewArticles/>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
  main *{
    font-family: PixelFont, serif;
    font-weight: bolder;
  }
  .article-list > div{
    margin-top: 20px;
  }
</style>