<script setup>
import {ref, onMounted, onBeforeMount, defineProps} from "vue";
import axios from "axios";
import {apiBaseUrl} from "@/config";
import CryptoJS from 'crypto-js';
import {ImagePreview, Snackbar} from "@varlet/ui";
import Vue3EmojiPicker from 'vue3-emoji-picker';
import 'vue3-emoji-picker/css';
import Cookies from "js-cookie";

const token = Cookies.get('N_token')
const meme_list = ref([]);
let user_info;
const user_avatar = ref("https://cravatar.cn/avatar/0")
const form_position = ref('body');  // 位置
const comment_placeholder_text = ref("嗯哼？什么都不说是吧？")
const reply_user_name = ref('')
const props = defineProps({
  page_id: String
})
const formData = ref({
    page_id: '084e0343a0486ff05530df6c705c8bb4',
    user_name: '',
    email: '',
    link: '',
    comment_text: '',

    parent_comment_id: null,
    reply_comment_id: null,
    reply_user_id: null,
    emoji_img: ''
  });
const summit_cooldown = ref(false)
const if_show_form_writing = ref(true);
const if_could_hidden_from = ref(false)
const if_show_emoji = ref(false)
const if_show_emoji_img = ref(false)
let total_top_comment_page_count = 1;
let total_top_comments_count = 1;
let current_top_comments_page = 0;   /* 1~ */

  const get_user_info = () =>{
    axios.get(`${apiBaseUrl}/user/get_user_info`).then(response=>{
      user_info = response.data.data
    })
  }

  const handleSubmit = debounce((event) => {
    /*
    提交表单
     */
    event.preventDefault(); // 阻止默认的表单
    formData.value.link = ensureLinkHttps(formData.value.link)
    summit_cooldown.value = true;
    axios.post(`${apiBaseUrl}/guest/send_comment`,
        formData.value,
        {headers: {'Content-Type': 'application/json'}})
        .then((response) => {
          Snackbar({
            content: response.data.message,
            type: response.data.type
          });
          save_user_info();
          add_new_comment(formData.value, response.data.data);

          formData.value.comment_text = '';
          formData.value.emoji_img = '';
        })
        .catch(() => {
          Snackbar({
            content: "出了点问题,可重新尝试一下吧 T.T",
            type: "error"
          })
        })
        .finally(() => {
          setTimeout(() => {
            summit_cooldown.value = false;
          }, 500
          )
    });
  }, 1000, true);
  const add_new_comment = (data, expand_data) =>{
    data.update(expand_data)
    if (data.parent_comment_id) {
      const index = total_comments.value.findIndex(top_comment => top_comment.id === data.parent_comment_id);
        total_comments.value[index].replies.unshift({
          id: data.id,
          user_id: CryptoJS.MD5(data.email),
          datetime: data.datetime,
          comment_text: data.comment_text,
          emoji_img: data.emoji_img,
          reply_user_id: data.reply_user_id,
          reply_comment_id: data.reply_comment_id
        });
      }
    else {
      total_comments.value.unshift({
        id: data.id,
        user_id: CryptoJS.MD5(data.email),
        datetime: data.datetime,
        comment_text: data.comment_text,
        emoji_img: data.emoji_img,
      })
    }
  }
  const handleEmojiSelect = (emoji)=>{
    formData.value.comment_text += emoji.i
  }
  function ensureLinkHttps(url) {
    if (url && !url.startsWith('https://') && !url.startsWith('http://')) {
      return 'https://' + url.replace('http://', '');
    }
    return url
  }
  function cancel_reply(){
    /*
    *  当用户点击取消回复
    */
    form_position.value = `#N${props.page_id}`;
    formData.value.parent_comment_id = null;
    formData.value.reply_comment_id = null;
    formData.value.reply_user_id = null;
    reply_user_name.value = null;
    comment_placeholder_text.value = "嗯哼？什么都不说是吧？";
  }
  function identify_users(){
    /*
    before：读取数据识别用户
     */
    if (token){
      localStorage.setItem("username", Cookies.get('nickname'));
      localStorage.setItem("email", Cookies.get('email'));
      localStorage.setItem("link", Cookies.get('link'));
    }

    if (localStorage.getItem('email') && localStorage.getItem("username")) {
      if_show_form_writing.value = false
      if_could_hidden_from.value = true
      // 保存信息到表单
      formData.value.user_name = localStorage.getItem("username")
      formData.value.email = localStorage.getItem("email")
      formData.value.link = localStorage.getItem("link")
    }
  }
  function get_user_avatar(){
    user_avatar.value = 'https://cravatar.cn/avatar/' + CryptoJS.MD5(formData.value.email)
  }
  function save_user_info(){
    localStorage.setItem('username', formData.value.user_name);
    localStorage.setItem('email', formData.value.email);
    localStorage.setItem('link', formData.value.link);
    if_show_form_writing.value = false;
    if_could_hidden_from.value = true;
  }
  function debounce(func, wait, immediate) {
    let timeout
    return function () {
      if (timeout) clearTimeout(timeout)
      if (immediate) {
        let callNow = !timeout
        timeout = setTimeout(() => {
          timeout = null
        }, wait)
        if (callNow) func.apply(this, arguments)
      } else {
        timeout = setTimeout(function () {
          func.apply(this, [event])
        }, wait)
      }
    }
  }
  const removeEmojiImg = () =>{
    if (!confirm("确定不使用表情包吗？")) return
    formData.value.emoji_img = '';
  }
  const selectedEmojiImg = (file) =>{
    formData.value.emoji_img = file.name
  }

  /*------ 评论区 ------*/
  const total_comments = ref([]);
  // 获取数据
  const fetch_top_comments = async (page_num) => {
    loading.value = true;
    axios.get(`${apiBaseUrl}/guest/get_page_comments?page_id=${formData.value.page_id}&page_num=${page_num}`)
        .then((response)=>{
          total_comments.value.push(...response.data.data);
          total_top_comments_count = response.data.total;
          total_top_comment_page_count = Math.ceil(total_top_comments_count / 10)
        })
        .catch ((error)=>{
          Snackbar({
            content: error.response.data.detail,
            type: "error"
          });
        })
        .finally(()=>{
          loading.value = false;
          if (current_top_comments_page >= total_top_comment_page_count){
            finished.value = true
          }
        })
  };
  function do_reply(parent_comment) {
    /*
    回复按钮
    */
    form_position.value = '#N' + parent_comment.id;

    formData.value.parent_comment_id = parent_comment.id;
    formData.value.reply_comment_id = parent_comment.id;
    formData.value.reply_user_id = parent_comment.user_id;

    reply_user_name.value = user_info[parent_comment.user_id]['name'];
    comment_placeholder_text.value = '@ ' + user_info[parent_comment.user_id]['name'];
  }

  const finished = ref(false)
  const loading = ref(false)

  const show_replies = (top_comment)=>{
    if (top_comment.replies !== undefined) return
    update_replies(top_comment, 1)
  }
  const load = () =>{
    current_top_comments_page += 1;
    fetch_top_comments(total_top_comment_page_count - 1)
  }

  const update_replies = (top_comment, current) => {
    top_comment.replies_page_num = current - 1;
    axios.get(`${apiBaseUrl}/guest/get_replies_comments?` + new URLSearchParams(
        {
          page_id: formData.value.page_id,
          parent_id: top_comment.id,
          page_num: top_comment.replies_page_num,
        })
    )
        .then(response=> {
          top_comment.replies = response.data.data
        })
  }

  onBeforeMount(() => {
    get_user_info();
    identify_users();
    get_user_avatar()
  })
  onMounted(() => {
    formData.value.page_id = props.page_id;
    form_position.value = `#N${props.page_id}`;

    // fetch_top_comments(0);  // 让加载列表自动获取
    axios.get(`${apiBaseUrl}/meme/get_memes`)
        .then((response)=>{
          meme_list.value = response.data.data.map(file_name => ({
                  cover: '/get_img/meme/' + file_name,
                  name: file_name,
                }))
        })
        .catch((error)=>{
          Snackbar({
            type: 'error',
            content: error
          })
        })
  })

</script>

<template>
  <!--   分割线     -->
  <div :id="'N' + props.page_id"></div>

  <!--   评论区     -->
  <div class="comment-area">
    <var-list
    :finished="finished"
    v-model:loading="loading"
    @load="load"
    loading-text="翻一下还有没有评论..."
    finished-text="qwq评论都被你看完了"
    error-text="报意思，出问题了💔">
    <ol class="comment-list parent" v-if="total_comments.length > 0">
      <li v-for="top_comment in total_comments" :key="top_comment.id" class="border-bottom" style="padding-bottom: 1rem">
        <div class="comment-body comment-parent" :id="'N' + top_comment.id">
          <a v-if="user_info[top_comment.user_id]['link']"
             :href="user_info[top_comment.user_id]['link']"
             class="position-absolute"
             target="_blank"
             title="看看ta的博客">
            <img :src="`https://cravatar.cn/avatar/${top_comment.user_id}`"
                 alt="0.0"
                 class="user-avatar img-fluid w-100">
          </a>
          <span v-else class="position-absolute">
            <img :src="`https://cravatar.cn/avatar/${top_comment.user_id}`"
                 alt="0.0"
                 class="user-avatar img-fluid w-100">
          </span>
          <div class="comment-main">
            <div class="comment-meta">
              <a v-if="top_comment.link" :href="user_info[top_comment.user_id]['link']"
                 target="_blank"
                 title="看看ta的博客">{{ user_info[top_comment.user_id]['name'] }}</a>
              <span v-else >{{ user_info[top_comment.user_id]['name'] }}</span>
              <var-badge type="primary"
                         style="margin-left: .2rem"
                         :value="user_info[top_comment.user_id]['tag']"
                         v-show="user_info[top_comment.user_id]['tag']"/>
              <br/>
              <time>{{ top_comment.datetime.replace('T', ' ') }}</time>
            </div>
            <div class="comment-text">
              <p>{{ top_comment.comment_text }}</p>
              <img v-lazy="'/get_img/meme/' + top_comment.emoji_img" alt="" style='width: 10rem;height: 10rem; object-fit: cover' v-if="top_comment.emoji_img"
                   @click="ImagePreview(`/get_img/meme/${top_comment.emoji_img}`)">
            </div>
            <div class="comment-action">
              <a @click="do_reply(top_comment)"
                 class="text-N-blue border-bottom"
                 title="对ta说">回复</a>
            </div>
          </div>
          <!-- 回复区 -->
          <div v-if="top_comment.replies_count > 0">
            共{{ top_comment.replies_count }}条子评论，
            <a class="text-N-blue" data-bs-toggle="collapse"
               :href="'#sub' + top_comment.id"
               aria-expanded="false"
               :aria-controls="'sub' + top_comment.id"
               @click="show_replies(top_comment)"
            >查看/收起</a>

          </div>
          <ol class="comment-list reply collapse" :id="'sub' + top_comment.id">
            <li v-for="reply_comment in top_comment.replies" :key="reply_comment.id">
              <div class="comment-body comment-child" :id="'N' + reply_comment.id">
                <a v-if="user_info[reply_comment.user_id]['link']" :href="user_info[reply_comment.user_id]['link']"
                   class="position-absolute"
                   target="_blank"
                   title="看看ta的博客">
                  <img :src="`https://cravatar.cn/avatar/${reply_comment.user_id}`"
                       alt="0.0"
                       class="user-avatar img-fluid w-100"/>
                </a>
                <span v-else class="position-absolute">
                  <img :src="`https://cravatar.cn/avatar/${reply_comment.user_id}`"
                       alt="0.0"
                       class="user-avatar img-fluid w-100"/>
                </span>
                <div class="comment-main">
                  <div class="comment-meta">
                    <a v-if="user_info[reply_comment.user_id]['link']"
                       :href="user_info[reply_comment.user_id]['link']"
                       target="_blank">{{ user_info[reply_comment.user_id]['name'] }}
                    </a>
                    <span v-else >{{ user_info[reply_comment.user_id]['name'] }}</span>
                    <var-badge type="primary"
                               style="margin-left: .2rem"
                               :value="user_info[reply_comment.user_id]['tag']"
                               v-show="user_info[reply_comment.user_id]['tag']"/>
                    <br/>
                    <time>{{ reply_comment.datetime.replace('T', ' ') }}</time>
                  </div>
                  <div class="comment-text">
                    <p>
                      <a class="text-N-pink"
                          :href="'#N' + reply_comment.reply_comment_id"
                          title="定位回复的信息">@{{ user_info[reply_comment.user_id]['name'] }}
                      </a>
                      {{ reply_comment.comment_text }}
                    </p>
                    <img v-lazy="'/get_img/meme/' + reply_comment.emoji_img"
                         style='width: 10rem;height: 10rem; object-fit: cover'
                         v-if="reply_comment.emoji_img"
                   @click="ImagePreview(`/get_img/meme/${reply_comment.emoji_img}`)">
                  </div>
                  <div class="comment-action">
                    <a @click="do_reply(reply_comment)"
                       class="text-N-blue border-bottom"
                       title="对ta说">回复</a>
                  </div>
                </div>
              </div>
            </li>
            <var-pagination :current="top_comment.replies_page_num"
                            :total="top_comment.replies_count"
                            :show-size-changer="false"
                            :size="10"
                            @change="update_replies(top_comment, $event)"/>
          </ol>
        </div>
      </li>
    </ol>
    </var-list>
  </div>

<!-- 表单 -->
  <Teleport :to="form_position">
    <transition name="fade">
      <!-- 回复表单 -->
      <div class="row">
        <form @submit.prevent="handleSubmit"
              method="post"
              class="row g-3 col"
              :aria-disabled="summit_cooldown">
          <fieldset :disabled="summit_cooldown">
            <div class="user-info row align-items-center" v-if="if_show_form_writing">
              <div class="col-1">
                <img :src="user_avatar" v-if="if_could_hidden_from"
                                      alt="头像"
                                      class="user-avatar"
                                      @click="if_show_form_writing = !if_show_form_writing"
                                      style="box-shadow: dodgerblue 1px 1px 10px"
                                      title="收！">
                <img :src="user_avatar" v-else
                                      alt="头像"
                                      class="user-avatar">
              </div>
              <div class="col-lg nes-field is-inline">
                <label for="email">邮箱:</label>
                <input type="email" id="email" v-model.trim.lazy="formData.email" maxlength="30"
                       class="nes-input" required placeholder="必填"
                       @focusout="get_user_avatar">
                </div>
              <div class="col-lg nes-field is-inline">
              <label for="username">昵称:</label>
              <input type="text" id="username" v-model.trim.lazy="formData.user_name" maxlength="20"
                     class="nes-input"
                     placeholder="暂不支持修改"
                     required>
            </div>
              <div class="col-lg nes-field is-inline">
                <label for="link">网址:</label>
                <input type="text" id="link" v-model.trim.lazy="formData.link" maxlength="32"
                       class="nes-input"
                       placeholder="你的博客地址">
              </div>
            </div>
            <h4 v-if="!if_show_form_writing">你好啊！
              <a class="text-N-blue" @click="if_show_form_writing = !if_show_form_writing" title="看看我的信息">
                <img :src="user_avatar"
                     alt="头像"
                     class="user-avatar"
                     style="margin-right: 10px; box-shadow: dodgerblue 1px 1px 8px">
                {{ formData.user_name }}
              </a>
            </h4>
            <div class="comment-contain row">
              <img :src="'/get_img/meme/' + formData.emoji_img"
                   v-show="formData.emoji_img"
                   class="col-2" @click="removeEmojiImg">
              <textarea id="comment" v-model.trim.lazy="formData.comment_text" maxlength="120"
                        :placeholder="comment_placeholder_text"
                        class="nes-textarea col"
                        rows="3"
                        required/>
            </div>
          </fieldset>
          <!-- 按钮 -->
          <fieldset class="action-bar d-flex"
                    :disabled="summit_cooldown"
                    @mouseleave="if_show_emoji=false;if_show_emoji_img=false">
            <div class="position-relative action">
              <a @click="if_show_emoji = !if_show_emoji; if_show_emoji_img=false">
                😀表情
              </a>
              <a @click="if_show_emoji_img = !if_show_emoji_img;if_show_emoji=false">
                🃏表情包
              </a>
              <Vue3EmojiPicker @select="handleEmojiSelect" v-show="if_show_emoji"
                               native hide-search :static-texts="{skinTone: '肤色'}"
                               style="position: absolute;top: 2rem;z-index: 1;"/>
              <div class="meme-picker-contain" v-show="if_show_emoji_img">
                <var-uploader v-model="meme_list" readonly @preview="selectedEmojiImg">
                  <template #default/>
                </var-uploader>
              </div>
            </div>
            <button type="submit"
                    :class="['nes-btn', 'col-1', { 'is-warning': summit_cooldown, 'is-primary': !summit_cooldown }]"
                    style="width: max-content; transition: all 0.5s ease; margin-left: auto">
              {{ summit_cooldown ? "提交中..." : "提交评论"}}
            </button>
            <button type="button"
                    :class="['nes-btn', 'col-1', { 'is-warning': summit_cooldown, 'is-primary': !summit_cooldown }]"
                    style="width: max-content"
                    v-show="formData.reply_user_id !== null"
                    @click="cancel_reply">取消回复</button>
          </fieldset>
        </form>
      </div>
    </transition>
  </Teleport>
</template>

<style scoped>
  .comment-area, form>fieldset *{
    font-weight: bolder;
    font-family: PixelFont, serif;
  }
  form textarea{
    margin: 10px 0 0 0;
  }
  img.user-avatar{
    border-radius: 50%;
    border: solid 1px black;

    width: 50px;
    height: 50px;
  }
  .action-bar > *{
    margin-left: 20px;
  }
  .meme-picker-contain{
    position: absolute;
    top: 2rem;
    z-index: 1;
    background-color: white;
    width: 30.07rem;
    padding: .5rem;
    height: 20rem;
    overflow-y: auto
  }

  /* 评论区 */
  .comment-area{
    padding: 30px 0;
    margin-top: 30px;
    border-top: gray 3px dashed;
  }
  .comment-list.parent > li:not(:first-child){
    margin-top: 20px;
  }
  .comment-body{
    position: relative;
  }

  .comment-main{
    padding-left: 60px;
  }
  .comment-child{
    margin-left: 60px;
    margin-top: 10px;
  }
  .comment-meta .name{
    font-size: 18px;
  }
  .comment-meta time{
    font-size: 13px;
    color: grey;
  }

  .comment-action{
    color: grey;
  }
  .comment-action >:not(:first-child){
    margin-left: 20px;
  }
</style>