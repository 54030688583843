<script setup>
  import HeaderHobby from "@/components/HeaderHobby.vue";
  import {onMounted, ref} from "vue";
  import CommentSection from "@/components/CommentSection.vue";
  import axios from "axios";
  import {apiBaseUrl} from "@/config";
  import {ImagePreview, Snackbar} from "@varlet/ui";
  import {like_items, likeTheMoment} from "@/common-api";
  import {reactive} from "@vue/runtime-dom";
  import MineInfo from "@/components/SideCardParts/MineInfo.vue";

  const symbolicAnimals_12 = ["hou","ji","gou","zhu", "shu","niu","hu","tu","long","she","ma","yang"];
  const years = ref();
  let moments_data;
  const get_moments = ()=>{
    axios.get(`${apiBaseUrl}/moment/get_moments?` + new URLSearchParams({year: 'all', page: 0}))
        .then((response)=>{
          moments_data = reactive(response.data.data)

          /* 年份排序 b-a是降 */
          years.value = Object.keys(moments_data)
          years.value.sort(function(a,b){return b - a})
        })
      .catch((error)=>{
        Snackbar({
          content: error.response.data.detail || error,
          type: "error"
        });
      });
  }

  const update_the_year_moments = (year, current) => {
    const page_num = current - 1;
    axios.get(`${apiBaseUrl}/moment/get_moments?` +
        new URLSearchParams({year: year, page: page_num})
    )
        .then((response)=>{
          moments_data[year].data = response.data.data[year]['data']
          const element = document.getElementById(`N${year}`);
          element.scrollIntoView({ behavior: 'smooth' })
        }).catch(()=>{
          Snackbar({
            type: "error",
            content: '获取数据失败'
          })
    })
  }
  const shengxiao = (year) => {
      return symbolicAnimals_12[year % 12];
  }

  onMounted(()=>{
    get_moments();
  })
</script>

<template>
  <HeaderHobby/>
  <main>
    <div class="container-xl row" style="padding: 10px 0">
      <!-- 说说主体 -->
      <div class="Diary nes-container is-rounded col">
        <div v-for="year in years" :key="year">
          <a class="year text-N-blue" data-bs-toggle="collapse"
             :href="'#N' + year"
             role="button"
             aria-expanded="true"
             :aria-controls="'N'+ year">
            <i :class="['N-icon', shengxiao(year)]"></i>
            <span :title=year style="margin-left: .5rem">{{ year }}</span>
          </a>
          <ul :id="'N' + year" class="collapse show">
            <li v-for="item in moments_data[year]['data']"
                :key="item.id" class="node">
              <span class="sign">{{ item.sign }}</span>
              <div class="node-info">
                <div class="datetime">
                  <i class="N-icon date little"/>
                  <time :title=item.date>{{ item.date }}</time>
                </div>
                <div class="row">
                  <p class="col">{{ item.content }}</p>
                  <div class="row-cols-3 row gx-2" v-if="item.imgs">
                    <img v-lazy="'/get_img/moment/' + img"
                         v-for="img in item.imgs.split(',')"
                         :key="img"
                         @click="ImagePreview(`/get_img/moment/${img}`)"
                         class="col">
                    {{ img }}
                  </div>
                </div>
              </div>
              <div class="action" style="padding-top: .5rem">
                <a @click="likeTheMoment('moment', item)"
                   :class="{'text-N-blue': like_items(item.id)}">
                  👍{{ item.like_count }}
                </a>
                <a @click="item.open = !item.open" class="text-N-blue">
                  评论区
                </a>
              </div>
              <div v-if="item.open">
                <CommentSection :page_id='item.id'/>
              </div>
            </li>
            <li class="node">
              <h4 class="sign text-N-pink">{{ year }}</h4>
              <div class="node-info">
                <var-pagination :current="moments_data[year].page_num"
                                :total="moments_data[year]['total']"
                                :show-size-changer="false" class="text-N-orange"
                                :size="3"
                                @change="update_the_year_moments(year, $event)"/>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="side-guider col-4 d-none d-lg-block" id="side-guider">
        <MineInfo/>
      </div>
    </div>
  </main>

</template>

<style scoped>
  .Diary *:not(.var-icon){
    transition: .5s ease;
    font-family: PixelFont, serif;
    font-weight: bolder;
    object-fit: cover;
  }

  .Diary > div{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  .Diary li{
      position: relative;
      --margin-left: 40px;
      margin-left: var(--margin-left);
      padding: 20px 30px;
      border-left: 3px solid;
  }
  .Diary li::before{
      position: absolute;
      left: -1px;
      top: 50%;
      transform: translate(-50%, -50%);
      color: currentColor;
      transition: all .5s ease-out;
  }

  .Diary .year{
    font-size: 26px;
    border-left-style: dashed;
    margin-left: 40px;
  }

  .Diary .year i{
    position: absolute;
    left: 0;
    translate: 80% 0;
  }
  .Diary .node .sign{
    position: absolute;
    left: -1px;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .5s ease-out;
  }
  .Diary .node .action{
    color: dimgray;
  }
  .node .action a:hover{
    color: dodgerblue;
  }

  .Diary .year:hover span{
      color: royalblue;
  }
</style>
