<script setup>
  import axios from "axios";
  import {apiBaseUrl} from "@/config";
  import {Snackbar} from "@varlet/ui";
  import {defineProps, onMounted, ref} from "vue";
  import Cookies from "js-cookie";
  const props = defineProps({
    user_id: {}
  })
  const token = Cookies.get('N_token');
  const formData = ref({})
  const form = ref(null)
  const handleSaveChange = ()=> {
    axios.post(`${apiBaseUrl}/admin/update_user`, formData.value, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response)=>{
        Snackbar({
            content: response.data.message,
            type: "success"
          });
    })
      .catch((error)=>{
        Snackbar({
          content: error.response.data.detail || error,
          type: "error"
        })
      })
  }

  onMounted(()=>{
    if (props.user_id){
      axios.get(`${apiBaseUrl}/admin/user?the_id=${props.user_id}`,{headers: {
        Authorization: `Bearer ${token}`,
        }
      }).then((response)=>{
        formData.value = response.data.data
      }).catch((error)=>{
        Snackbar({
          type: 'error',
          content: error || error.response.data.detail
        })
      })
    }
  })
</script>

<template>
  <var-form
      ref="form"
      scroll-to-error="start">
      <var-input
        placeholder="ID"
        v-model="formData.id"
        disabled
      />
      <var-input
        placeholder="昵称"
        v-model="formData.name"
      />
      <var-input
        placeholder="邮箱"
        v-model="formData.email"
      />
      <var-input
        placeholder="网址"
        v-model="formData.link"
      />
      <span>状态 </span>
      <var-switch
        v-model="formData.state"
      />
      <var-input
        placeholder="标签"
        v-model="formData.tag"
      />

    </var-form>
  <var-space direction="column" :size="[14, 0]">
    <var-button block type="primary" @click="handleSaveChange">
        保存修改
      </var-button>
  </var-space>
</template>

<style scoped>

</style>