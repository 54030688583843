<script setup>
  import { useRouter } from 'vue-router';
  import HeaderHobby from "@/components/HeaderHobby.vue";
  import Cookies from "js-cookie";

  const router = useRouter();

  function logout() {
    if (!confirm("确定要注销登录吗？")) return

    Cookies.remove('N_token');
    router.push('/');
  }
</script>

<template>
  <HeaderHobby/>
  <main>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
      <div class="container-fluid">
        <a class="navbar-brand" @click.prevent="router.back()" href="#">🔙 Norvend</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <router-link class="nav-link" to="/N_admin/db_manager/">数据库控制台</router-link>
          <router-link class="nav-link" to="/N_admin/user/manage">用户</router-link>
          <router-link class="nav-link" to="/N_admin/ip_record/">IP信息</router-link>
          <router-link class="nav-link" to="/N_admin/article/manage">所有文章</router-link>
          <router-link class="nav-link" to="/N_admin/meme/">表情包</router-link>
          <router-link class="nav-link" to="/N_admin/moment/manage">说说</router-link>
          <a class="nav-link text-N-pink" @click.prevent="logout()" href="#">Logout</a>
        </div>
      </div>
      </div>
    </nav>

    <router-view/>
  </main>
</template>

<style scoped>
  a{
    font-family: PixelFont, serif;
    font-weight: bolder;
  }

  a[aria-current='page'] {
    color: darkorange;
    border-bottom: darkorange 2px solid;
  }
</style>
