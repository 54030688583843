<script setup>
  import axios from "axios";
  import {apiBaseUrl} from "@/config";
  import {Snackbar} from "@varlet/ui";
  import Cookies from "js-cookie";
  import {onBeforeMount, ref} from "vue";
  const token = Cookies.get('N_token')
  const datas = ref()
  const getDataFromTable =()=> {
    const formData = new FormData();
    formData.append('table_name', 'user');
    axios.post(`${apiBaseUrl}/admin/get_data_from_table`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        .then((response)=>{
          datas.value = response.data.data;
        })
        .catch ((error) => {
          Snackbar({
            content: error.response.data.detail || error,
            type: "error"
          });
        });
  }
  const changeUserInfo = (the_user, action='all')=>{
    if (action === 'state') the_user.state = !the_user.state;
    axios.post(`${apiBaseUrl}/admin/update_user`, the_user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response)=>{
      Snackbar({
        type: "success",
        content: response.data.message
      })
    }).catch((error)=>{
      Snackbar({
        type: 'error',
        content: error.response.data.detail
      })
    })
  };
  const handleDeleteUser = (the_user) =>{
    const formData = new FormData()
    formData.append("del_id", the_user.id)
    if (confirm(`确定要删除用户: ${the_user.name}`)){
      axios.post(`${apiBaseUrl}/admin/del_user`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response)=>{
        Snackbar({
            content: response.data.message,
            type: "success"
          });
      })
      .catch((error)=>{
        Snackbar({
          content: error.response.data.detail || error,
          type: "error"
        })
      })
    }
  }

  onBeforeMount(()=>{
    getDataFromTable();
  })
</script>

<template>
    <var-table>
      <thead>
        <tr>
          <th>名字</th>
          <th>邮箱</th>
          <th>网址</th>
          <th>状态</th>
          <th>标签</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in datas" :key="data.id">
          <td>{{ data.name }}</td>
          <td>{{ data.email }}</td>
          <td>{{ data.link }}</td>
          <td><var-switch v-model="data.state" @change="changeUserInfo(data, 'state')"/></td>
          <td>{{ data.tag }}</td>
          <td class="action">
            <router-link :to="'./' + data.id">修改</router-link>
            <a @click="handleDeleteUser(data)">删除</a>
          </td>
        </tr>
      </tbody>
    </var-table>
</template>

<style scoped>

</style>