<script setup>
  import {onMounted, onUnmounted, ref} from "vue";

  const page_is_loaded = ref(false);
  let loadHandler = null;  // 页面加载监听事件

  const scroll_to_id = () => {
    setTimeout(()=>{
      const hash = location.hash.replace('#', '');
      const element = document.getElementById(hash);
      if (element) {
          element.scrollIntoView({ behavior: 'smooth'})
        }
      }, 500)
  }

  onMounted(() => {
    loadHandler = () => {
      page_is_loaded.value = true;
      // 访问地址带#时平滑滚动
      scroll_to_id()
    }
    window.addEventListener('load', loadHandler);
  })

  onUnmounted(() => {
    if (loadHandler) {
      window.removeEventListener('load', loadHandler);
    }
  });
</script>

<template>
  <div :class="['preloader', page_is_loaded ? 'page-loaded' : 'page-loading']" ref="componentRef">
    <div class="N-loading">
      <div class="N-bg"></div>
      <div class="N-top"></div>
      <div class="N-right"></div>
      <div class="N-bottom"></div>
      <div class="N-left"></div>
    </div>
  </div>
</template>

<style scoped>
  .preloader{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: fixed;
    overflow: inherit;

    -moz-transition: all .3s ease;
    transition: all .3s ease;

    transition-duration: 500ms;
    -webkit-transition-duration: 500ms;

    left: 0;
    top: 0;
    z-index: 3;

  }
  .preloader * {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    transition-duration: 500ms;
    -webkit-transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  }

  .N-loading{
    height: 100%;
    width: 100%;
    position: relative;
  }
  .N-loading > *{
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .N-top{
    clip-path: polygon(33% 0%, 67% 0%, 67% 20%, 63% 20%, 63% 70%, 38% 20%, 33% 20%);
    //clip-path: polygon(30% 0%, 70% 0%, 70% 20%, 65% 20%, 65% 68%, 36% 20%, 30% 20%);
    background: dimgray;
    top: 0;
  }
  .N-bottom{
    clip-path: polygon(33% 100%, 33% 80%, 37% 80%, 37% 30%, 62% 80%, 67% 80%, 67% 100%);
    //clip-path: polygon(32% 100%, 32% 80%, 35% 80%, 35% 32%, 64% 80%, 70% 80%, 70% 100%);
    background: dimgray;
    bottom: 0;
  }
  .N-right{
    clip-path: polygon(67% 0%, 100% 0%, 100% 100%, 67% 100%);
    //-webkit-clip-path: polygon(70% 0%, 100% 0%, 100% 100%, 70% 100%);
    background: dimgray;
    right: 0;
  }
  .N-left{
    clip-path: polygon(0 0, 33% 0, 33% 100%, 0 100%);
    background: dimgray;
    left: 0;
  }
  .N-bg{
    background: linear-gradient(-45deg, lightskyblue, dodgerblue, dimgray);
    background-size: 300% 300%;
    animation-name: gradient_bg;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }


  .preloader.page-loaded{
    transition-delay: 1.2s;
    left: -100vw;
  }
  .page-loaded .N-bg{
    opacity: 0;
    transition-delay: 0s;
  }
  .page-loaded .N-top{
    top: -100%;
    transition-delay: .2s;
  }
  .page-loaded .N-right{
    //right: -30%;
    width: 0;
    transition-delay: 0.4s;
  }
  .page-loaded .N-bottom{
    bottom: -100%;
    transition-delay: 0.6s;
  }
  .page-loaded .N-left{
    left: -30%;
    transition-delay: 0.8s;
  }
  
  @keyframes gradient_bg {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
</style>