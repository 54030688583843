<script setup>
import {ref} from "vue";
import UserLogin from "@/components/Admin/UserLogin.vue";

  const menu_is_open = ref(false);
  const bgm_is_playing = ref(false)
  const audioPlayer = ref(null)
  const toggle_menu = ()=>{
    menu_is_open.value = !menu_is_open.value
  }

  function  toggle_bgm_play() {
    bgm_is_playing.value = ! bgm_is_playing.value;
    if (bgm_is_playing.value) {
      audioPlayer.value.play();
    } else {
      audioPlayer.value.pause();
    }
  }

</script>

<template>
<!--  菜单 -->
  <div :class="['navigation-menu', 'row' ,{'open': menu_is_open}]">
    <div class="nav-blue col">
      <p class="avatar N-blue"></p>
      <ul class="text-center">
        <li><router-link to="/guest/" @click="toggle_menu"><h1>留言</h1></router-link></li>
        <li><router-link to="/meme/" @click="toggle_menu"><h1>表情包</h1></router-link></li>
      </ul>
    </div>
    <div class="nav-pink col">
      <p class="avatar N-pink"/>
      <ul class="text-center" >
        <li><router-link to="/" @click="toggle_menu"><h1>关于小诺</h1></router-link></li>
        <li><router-link to="/moment" @click="toggle_menu"><h1>说说</h1></router-link></li>
      </ul>
    </div>
    <div class="nav-orange col">
      <p class="avatar N-orange"></p>
      <ul class="text-center">
        <li><a href="https://norvend.pythonanywhere.com" target="_blank"><h1>旧项目</h1></a></li>
        <li><router-link to="/article/" @click="toggle_menu"><h1>文章</h1></router-link></li>
        <li><UserLogin></UserLogin></li>
      </ul>
    </div>
  </div>

<!-- 导航栏 -->
  <nav class="navbar">
    <h1 class="">Norvend</h1>
    <a :class="['bgm-player row flex-nowrap position-relative', {'playing' : bgm_is_playing}]"
       @click="toggle_bgm_play">
      <audio  ref="audioPlayer" hidden loop
              src='https://norvend.pythonanywhere.com/static/music/NeedYouNow.mp3'/>
      <span class="position-absolute text-center">{{ bgm_is_playing ? '🎶' : ''  }}</span>
      <div class="door col left-door d-flex overflow-hidden justify-content-between">
        <p style="border-left: 1px black solid; margin-bottom: 0" class="col"></p>
        <p style="border-left: 1px black solid; margin-bottom: 0" class="col"></p>
        <p style="border-left: 1px black solid; margin-bottom: 0" class="col"></p>
        <p style="border-left: 1px black solid; margin-bottom: 0" class="col"></p>
      </div>
      <div class="door col right-door d-flex overflow-hidden justify-content-between">
        <p style="border-right: 1px black solid; margin-bottom: 0" class="col"></p>
        <p style="border-right: 1px black solid; margin-bottom: 0" class="col"></p>
        <p style="border-right: 1px black solid; margin-bottom: 0" class="col"></p>
        <p style="border-right: 1px black solid; margin-bottom: 0" class="col"></p>
      </div>
    </a>
    <div>
      <button class="nes-btn is-warning" @click="toggle_menu">
        菜单
      </button>
    </div>
  </nav>
</template>

<style scoped>
  .navbar{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 30px;
    color: white;
  }

  .navbar h1{
    background-image: -webkit-linear-gradient(left, dodgerblue, hotpink 50%, darkorange);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-background-size: 200% 100%;
    -webkit-animation:  maskedAnimation 3s infinite ease-in-out alternate-reverse;
    padding: 0 10px;
    font-style: italic;

    font-weight: bolder;
  }
  .navbar .bgm-player{
    border: 1px black solid;
    height: 3.6rem;
    width: 3.6rem;
    border-radius: 50%;
    box-shadow: dodgerblue 1px 1px 8px;

    background: url("@/assets/img/sprite-animat/player-playing.png") no-repeat;
    background-position-y: 0.8rem;
  }
  .bgm-player.playing{
    animation-name: bgm-player-playing;
    animation-delay: 0.5s;
    animation-timing-function: steps(4);
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  .bgm-player.playing>span{
    top: 0;
    animation: the_flow_musical;
    animation-iteration-count: infinite;
    animation-delay: 1.5s;
    animation-duration: 2s;
    opacity: 0;
  }
  .bgm-player .door{
    background: #9C5600;
    border: 1px black solid;
    padding: 0;

    transform: rotateY(0deg);
    transition: all 1s ease-in;
  }
  .bgm-player .left-door{
    border-radius: 3.6rem 0 0 3.6rem;
    transform-origin: left;
  }
  .bgm-player .right-door{
    border-radius: 0 3.6rem 3.6rem 0;
    transform-origin: right;
  }
  .bgm-player.playing .door{
    transform: rotateY(80deg);
  }

  .navbar button.nes-btn{
    width: 4rem;
  }

  .navigation-menu{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: -100%;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    transition-duration: 500ms;
    -webkit-transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.86,0,0.07,1);
    -webkit-transition-timing-function: cubic-bezier(0.86,0,0.07,1);

    transition-delay: 1s;
    font-family: PixelFont, serif;
    font-weight: bolder;
  }
  .navigation-menu >*{
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    transition-duration: 500ms;
    -webkit-transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.86,0,0.07,1);
    -webkit-transition-timing-function: cubic-bezier(0.86,0,0.07,1);

    height: 1px;
    transition-delay: 0.5s;
    overflow: hidden;
  }
  .navigation-menu ul{
    margin: 2rem auto 0;
    width: fit-content
  }

  /*菜单打开 + 内容*/
  .navigation-menu.open{
    transition-delay: 0s;
    top: 0;
  }

  .navigation-menu h1{
    font-weight: bolder;
    margin-bottom: 1rem;
  }
  .navigation-menu .nav-blue{
    background: dodgerblue;
    transition-delay: 0.2s;
  }
  .navigation-menu.open .nav-blue {
    transition-delay: 0.2s;
    height: 100%;
  }

  .navigation-menu .nav-pink{
    background: hotpink;
    transition-delay: 0.4s;
  }
  .navigation-menu.open .nav-pink {
    transition-delay: 0.4s;
    height: 100%;
  }

  .navigation-menu .nav-orange{
    background: darkorange;
    transition-delay: 0.6s;
  }
  .navigation-menu.open .nav-orange{
    transition-delay: 0.6s;
    height: 100%;
  }

  .avatar{
    margin-top: 6rem;
  }

  @keyframes maskedAnimation {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: 100%;
    }
  }

  @keyframes bgm-player-playing {
    from{
      background-position-x: 2px;
    }
    to {
      background-position-x: 140%;
    }
  }

  @keyframes the_flow_musical {
    20%{
      top: 0;
      opacity: 100;
    }
    100%{
      top: 4rem;
      opacity: 0;
    }
  }


  /* ---------------------媒体查询 max480-----------*/
  @media (min-width: 768px) and (orientation: portrait) {
    .navbar h1{
      font-size: 3.6rem
    }
    .navbar .bgm-player{
      height: 6rem;
      width: 6rem;
      background-size: auto 4.9rem;
      background-position-y: 1rem;
    }
    .navbar button.nes-btn{
      height: 5rem;
      width: 7rem;
      font-size: 1.5rem;
    }

    .navigation-menu.row{
      flex-direction: column;
    }
    .navigation-menu.row >* {
      flex: 0 0 0;
    }
    .navigation-menu.row.open >*{
      flex: 1 0 0;
    }
    .navigation-menu.row.open .nav-orange{
      transition-delay: 0.2s;
    }
    .navigation-menu.row.open .nav-blue{
      transition-delay: 0.6s;
    }
    .navigation-menu.row ul{
      margin: 2rem unset unset;
      display: flex;
      justify-content: center;
    }
  }
</style>

<style>
  .navigation-menu a,h1{
    transition: all 0.3s ease-in-out;
    color: black;
    ::before, ::after {
      transition: all 0.3s ease-in-out;
      margin: 0 1.5rem;
      opacity: 0;
    }
    ::before{
      content: ' >';
    }
    ::after{
      content: '<';
    }
  }

  .navigation-menu a:hover{
    ::before,::after{
      margin: 0 0.5rem;
      opacity: 1;
      color: white;
    }
    color: white;
    h1{
      color: white;
    }
  }
</style>