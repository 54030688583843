<script setup>
  const headerHobbyImg = [
    {"src_part": "selenaGomez.jpg", "title": "SELENA GOMEZ", "active": 'active'},
    {"src_part": "minecraft.jpg", "title": "MINECRAFT"},
    {"src_part": "tombRaider.jpg", "title": "TOMB RAIDER"},
    {"src_part": "tomandjerry.jpg", "title": "TOM AND JERRY"},
  ]
</script>

<template>
  <header class="fixed-top z-0">
    <div id="carouselExampleCaptions"
         data-bs-ride="carousel"
         data-bs-pause="false"
         class="h-100 w-100 carousel slide">
      <div class="carousel-indicators">
        <button v-for="(item, index) in headerHobbyImg" :key="index"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                :data-bs-slide-to="index"
                :class="{active: item.active}"
                :aria-current="item.active === 'active'"
                :aria-label="'Slide' + index+1"></button>
      </div>
      <div class="carousel-inner h-100 w-100">
        <div v-for="item in headerHobbyImg"
             :class="['carousel-item h-100 w-100', item.active]" :key="item.title">
          <img v-lazy="require('@/assets/img/HeaderHobby/' + item.src_part)"
               class="d-block w-100 h-100 object-fit-cover"
               :alt=item.title>
          <div class="carousel-caption">
            <h1>{{ item.title }}</h1>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </header>
</template>

<style scoped>
  .carousel-item h1{
    font-size: 7vw;
    font-weight: inherit;
    font-style: italic;
    color: hotpink;
  }
</style>