<script setup>
import {onBeforeMount, ref} from "vue";
import axios from "axios";
import {apiBaseUrl} from "@/config";
import {Snackbar} from "@varlet/ui";
import Cookies from "js-cookie";
import {common_upload_img} from "@/common-api";

  const token = Cookies.get('N_token');
  const meme_list = ref();
  const next_index = ref();
  const handleAfterRead = (file) => {
    file.state = 'loading'
    common_upload_img(file.file, 'meme')
        .then((response)=>{
          file.url = '/get_img/meme/' + response.data.file_name;
          file.cover = '/get_img/meme/' + response.data.file_name;
          file.state = 'success'
          next_index.value++;
        })
        .catch(error=>{
          Snackbar({
            content: error.response.data.detail || error,
            type: "error"
          });
          file.state = 'error'
        })
  }
  const handleBeforeRead = (file) =>{
    if (file.name.split('.')[0] !== `img_${next_index.value}`) {
      alert(`需将文件名改成：img_${next_index.value}`)
      return false
    }
    return true
  }
  const handleBeforeRemove = (file) => {
    if (!confirm(`确定删除表情包：${file.name} 吗？`)) return false
    if (file.state !== 'success') return true
    const formData = new FormData();
    formData.append('group', 'meme')
    formData.append('img_name', file.name)
    axios.post(`${apiBaseUrl}/admin/del_img`,formData,
        {
          headers: {Authorization: `Bearer ${token}`}
        })
        .then((response)=>{
          Snackbar({
            type: 'success',
            content: response.data.message
          })
        })
        .catch((error)=>{
          Snackbar({
            type: 'error',
            content: error.response.data.detail
          })
        })
    return true
  }
  onBeforeMount(()=>{
    axios.get(`${apiBaseUrl}/meme/get_memes`)
        .then((response)=>{
          meme_list.value = response.data.data.map(file_name => ({
                  url: '/get_img/meme/' + file_name,
                  cover: '/get_img/meme/' + file_name,
                  name: file_name,
                  state: 'success'
                }))
          next_index.value = response.data.next_index;
        })
        .catch((error)=>{
          Snackbar({
            type: 'error',
            content: error
          })
        })
  })
</script>

<template>
  <h2 class="text-center">img_{{ next_index }}</h2>
  <var-uploader v-model="meme_list"
                @before-remove="handleBeforeRemove"
                @before-read="handleBeforeRead"
                @after-read="handleAfterRead"/>

</template>

<style scoped>

</style>