<script setup>

</script>

<template>
  <div class="text-center">
    <p class="avatar N-pink"></p>
    <h3 class="text-N-pink" style="margin-top: 1rem">小诺</h3>
    <div class="text-start" style="padding: .5rem 2rem">
      <p><strong>称呼：</strong>Norvend/诺凡德</p>
      <p><strong>爱好：</strong>💻、🎮、🏞、🎧、🐱、🏸</p>
      <p><strong>专业：</strong>智能科学与技术💻</p>
      <p><strong>生日：</strong><i class="N-icon tu"/>12月30号</p>
      <p><strong>颜色：</strong><span class="text-N-blue">蓝🌊</span></p>
      <p><strong>星座：</strong>摩羯座♑</p>
    </div>
  </div>
</template>

<style scoped>
  strong{
    font-weight: bolder;
    font-size: 1.2rem;
    color: darkorange;
  }
</style>