<script setup>
import {ref} from "vue";
  import axios from "axios";
  import {apiBaseUrl} from "@/config";
  import {useRouter} from "vue-router";
  import {debounce} from "@/common-api";
  import {Snackbar} from "@varlet/ui";
import Cookies from "js-cookie";

  const form = ref()
  const disabled = ref(false)
  const formData = ref({
    username: null,
    password: null
  })

  const router = useRouter();
  const handelSummit = debounce(()=>{
    form.value.validate().then((result)=>{
      if (!result) return
      axios.post(`${apiBaseUrl}/token`, formData.value, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response)=>{
        Cookies.set('N_token', response.data.access_token, {expires: 1});
        Cookies.set('email', response.data.user_info["email"], {expires: 2})
        Cookies.set('nickname', response.data.user_info["nickname"], {expires: 2})
        Cookies.set('link', response.data.user_info["link"], {expires: 2})

        console.log(response.data)
        Snackbar({
            content: `😀你好啊，${response.data.user_info["nickname"]}`,
            type: "success"
        })
        router.push('/N_admin/');
        setTimeout(()=>{window.location.reload()}, 500)
      }).catch(()=>{
        Snackbar({
            content: "我不认识你！奏楷奏楷！😡",
            type: "error"
        })
      })
    })
  }, 2000, false)

  const identifyUser = ()=>{
    if (Cookies.get('N_token')) {
      router.push('/N_admin/');
      setTimeout(()=>{window.location.reload()}, 500)
    }
  }
</script>

<template>
  <a data-bs-toggle="modal" data-bs-target="#UserLogin" @click="identifyUser"><h1 style="font-weight: bold">诺之钥</h1></a>

  <!-- 登录窗口 -->
  <Teleport to="body">
    <div class="modal fade" id="UserLogin" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">你是小诺派来的吗？</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
             <var-form :disabled="disabled" scroll-to-error="start" ref="form">
                 <var-input placeholder="名字" :rules="[v => !!v || '名字嗦一下']"
                            v-model="formData.username"/>
                 <var-input type="password" placeholder="密码"
                            :rules="[v => !!v || '密码不能为空', (v) => v.length >= 16 || '密码不对']"
                            v-model="formData.password"/>
             </var-form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">告辞</button>
            <button type="button" class="btn btn-primary" @click="handelSummit">是的</button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
</style>