<script setup>
  import axios from "axios";
  import {apiBaseUrl} from "@/config";
  import {Snackbar} from "@varlet/ui";
  import Cookies from "js-cookie";
  import {onBeforeMount, ref} from "vue";
  const token = Cookies.get('N_token')

  const datas = ref()
  const get_data_from_table =()=> {
    const formData = new FormData();
    formData.append('table_name', 'ip_record');
    axios.post(`${apiBaseUrl}/admin/get_data_from_table`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        .then((response)=>{
          datas.value = response.data.data;
        })
        .catch ((error) => {
          Snackbar({
            content: error.response.data.detail || error,
            type: "error"
          });
        });
  }
  const changeIpRecordInfo =(the_ip, action='all')=>{
    if (action === 'state') the_ip.state = !the_ip.state
    axios.post(`${apiBaseUrl}/admin/update_ipRecord`,
        {"ip": the_ip.ip, "state": the_ip.state, 'where': the_ip.where},
        {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
        .then((response)=>{
          Snackbar({
            type: "success",
            content: response.data.message
          })
        })
        .catch((error)=>{
      Snackbar({
        type: 'error',
        content: error.response.data.detail
      })
    })
  }
  const deleteIprecord = (the_ip) => {
    if (!confirm(`确定删除IP：${the_ip}吗？`)) return

    const formData = new FormData()
    formData.append("the_ip", the_ip)
    axios.post(`${apiBaseUrl}/admin/del_ipRecord`,
        formData,
        {
          headers: {Authorization: `Bearer ${token}`}
        })
        .then((response)=>{
          Snackbar({
            type: 'success',
            content: response.data.message
          })
        })
        .catch((error)=>{
          console.log(error);
          Snackbar({
            type: 'error',
            content: error.response.data.detail
          })
        })
  }
  onBeforeMount(()=>{
    get_data_from_table();
  })
</script>

<template>
  <var-table>
    <thead>
      <tr>
        <th>最新时间</th>
        <th>IP</th>
        <th>用户名字</th>
        <th>所属地</th>
        <th>状态</th>
        <th>操作</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="data in datas" :key="data.ip">
        <td>{{ data.last_time }}</td>
        <td>{{ data.ip }}</td>
        <td>{{ data.user_name }}</td>
        <td><var-input v-model="data.where" /></td>
        <td><var-switch v-model="data.state" @change="changeIpRecordInfo(data, 'state')"/></td>
        <td class="action">
          <a @click="changeIpRecordInfo(data)">保存修改</a>
          <a @click="deleteIprecord(data.ip)">删除</a>
        </td>
      </tr>
    </tbody>
  </var-table>
</template>

<style scoped>

</style>