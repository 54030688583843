import axios from "axios";
import {apiBaseUrl} from "@/config";
import {Snackbar} from "@varlet/ui";
import Cookies from "js-cookie";

const token = Cookies.get('N_token');
export const upload_article_img = async(img_file)=>{
    const formData = new FormData();
    formData.append('img_file', img_file);
    return await axios.post(`${apiBaseUrl}/admin/upload_article_img`,
        formData,
        {headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
      }
    })
}

export const common_upload_img = async(img_file, group) =>{
    const formData = new FormData();
    formData.append('img_file', img_file);

    return await axios.post(`${apiBaseUrl}/admin/upload_img?` + new URLSearchParams({group: group}),
        formData,
        {headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
      }
    })
}
// 防抖
export function debounce(func, wait, immediate) {
    let timeout
    return function () {
      if (timeout) clearTimeout(timeout)
      if (immediate) {
        let callNow = !timeout
        timeout = setTimeout(() => {
          timeout = null
        }, wait)
        if (callNow) func.apply(this, arguments)
      } else {
        timeout = setTimeout(function () {
          func.apply(this, [event])
        }, wait)
      }
    }
}

// like_count
export const likeTheMoment = (page_name, the_item) =>{
    let likeArray
    if (localStorage.getItem('like')){
        likeArray = JSON.parse(localStorage.getItem('like'))
        if (likeArray.indexOf(the_item.id) !== -1) {
            Snackbar({
                content: '🙂赞一次就足够啦~',
                type: 'success'
            });
            return
        }
    }
    else{
        likeArray = []
    }
    const formData = new FormData();
    formData.append('the_id', the_item.id);
    axios.post(`${apiBaseUrl}/${page_name}/like`, formData)
    .then((response)=>{
      Snackbar({
        content: response.data.message,
        type: "success"
      })

      the_item.like_count += 1;
      the_item.like = true;

      likeArray.push(the_item.id);
      localStorage.setItem('like', JSON.stringify(likeArray))
    }).catch((error)=>{
      Snackbar({
        content: error.response.data.detail,
        type: "error"
    })
})
}
/*搭配 likeTheMoment使用 */
export const like_items = (the_id) => {
    if (localStorage.getItem('like')){
        const likedArray = JSON.parse(localStorage.getItem('like'))
        return likedArray.indexOf(the_id) !== -1
    }
    return false
  }