<script setup>
  import {defineProps, onBeforeMount, ref} from "vue";
  import axios from "axios";
  import {apiBaseUrl} from "@/config";
  import VMdPreview from "@kangc/v-md-editor/lib/preview";
  import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress";
  import Prism from "prismjs";
  import CommentSection from "@/components/CommentSection.vue";
  import {like_items, likeTheMoment} from "@/common-api";
  import NewArticles from "@/components/SideCardParts/NewArticles.vue";

  VMdPreview.use(vuepressTheme, {
      Prism,
  });

  const props = defineProps({
    article_id: String
  })

  const titles = ref([])
  let anchors;

  const markdownPreview = ref(null);
  const article_data = ref(false)
  function handleAnchorClick(anchor) {
    const heading = markdownPreview.value.$el.querySelector(`[data-v-md-line="${anchor.lineIndex}"]`);
    if (heading) {
      markdownPreview.value.scrollToTarget({
        target: heading,
        scrollContainer: window,
        top: 60,
      });
    }
  }

  onBeforeMount(async ()=>{
    // 获取文章
    await axios.get(`${apiBaseUrl}/article/${props.article_id}`).then((response)=>{
      article_data.value = response.data.data
    });

    // 生成文章目录
    anchors = markdownPreview.value.$el.querySelectorAll('h1, h2, h3, h4, h5, h6');
    const tmp_titles = Array.from(anchors).filter((title) => !!title.innerText.trim());

    if (!tmp_titles.length) {
      titles.value = [];
      return;
    }

    const hTags = Array.from(new Set(tmp_titles.map((title) => title.tagName))).sort();

    titles.value = tmp_titles.map((el) => ({
      title: el.innerText,
      lineIndex: el.getAttribute('data-v-md-line'),
      indent: hTags.indexOf(el.tagName),
    }));
  })
</script>

<template>
  <header class="fixed-top z-0 card text-white">

    <div class="card-img-overlay d-flex align-items-center justify-content-center">
      <div class="article-info-box container">
        <h1 style="font-size: 3rem; color: white"> {{ article_data.title }} </h1>
        <div style="font-size: 1.2rem">
          <p :title="'作者：' + article_data.author">作者：{{ article_data.author }}</p>
          <p :title="'创作时间：' + article_data.create_time"> 创作时间：{{ article_data.create_time }}  </p>
          <p :title="'更新时间：' + article_data.last_update_time">更新时间：{{ article_data.last_update_time }}</p>
          <p :title="'浏 览 量：' + article_data.view_count"> 浏 览 量 ：{{ article_data.view_count }} </p>
        </div>
      </div>
    </div>
    <img :src="'/get_img/article/' + article_data.cover_img"
         :alt="article_data.cover_img"
         class="h-100 w-100 card-img object-fit-cover">
  </header>
  <main v-if="article_data">
    <div class='container-xxl bg-N-orange' style="height: 22px; border-radius: 11px"></div>
    <div class="row container-xl">
      <div class="col-12 col-md-8">
        <v-md-preview class="overflow-auto" :text="article_data.content" ref="markdownPreview"/>
        <div class="action d-flex justify-content-center">
          <button @click="likeTheMoment('article', article_data)" class="nes-btn"
             :class="{'text-N-blue': like_items(article_data.id)}">
            <i :class="['nes-icon is-small heart', {'is-empty': !like_items(article_data.id)}]"></i>{{ article_data.like_count }}
          </button>
        </div>
      </div>
      <!--  侧边栏  -->
      <div class="side-guider col-4 d-none d-lg-block" id="side-guider">
        <div class="side-sticky">
          <h1>本文目录</h1>
          <ul>
            <li v-for="(anchor, index) in titles" :key="index"
                :style="{ padding: `5px 0 5px ${anchor.indent * 20}px` }"
                @click="handleAnchorClick(anchor)" class="directory-item">
                <a style="cursor: pointer">◀ {{ anchor.title }}</a>
            </li>
          </ul>
          <br>
          <NewArticles/>
        </div>
      </div>
    </div>
    <!--  评论区  -->
    <div class="container-xl">
      <CommentSection :page_id="article_id"/>
    </div>
  </main>
</template>

<style scoped>
</style>