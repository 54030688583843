<script setup>

  import {onBeforeMount, onMounted, ref} from "vue";
  import axios from "axios";
  import {apiBaseUrl} from "@/config";
  import {useRouter} from "vue-router";
  import Cookies from "js-cookie";
  import {Snackbar} from "@varlet/ui";

  const token = Cookies.get('N_token');
  const router = useRouter();
  const pending_comment_list = ref([]);
  const pending_comment_cooldown = ref(false);
  const selected_pending_id_list = ref([])
  const solve_pending_comments = (api) => {
    if (selected_pending_id_list.value.length < 1) return

    pending_comment_cooldown.value = true
    const formData = new FormData()
    formData.append("passed_id_list", Array(selected_pending_id_list.value))

    axios.post(`${apiBaseUrl}/admin/${api}`,
          formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response)=> {
          Snackbar({
            content: response.data.message,
            type: "success"
          });
          selected_pending_id_list.value = [];
          get_pending_comments();
      })
        .catch((error)=>{
          Snackbar({
            content: error.response.data.detail || error,
            type: "error"
          });
        })
        .finally(()=>{
          pending_comment_cooldown.value = false;
        })
  }
  const get_pending_comments = ()=> {
    axios.get(`${apiBaseUrl}/admin/get_pending_comments`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
        .then((response)=>{pending_comment_list.value = response.data.data;})
        .catch(()=>{router.push('/')})
  }

  const tables = ref([])
  const tables_columns = ref([])
  const datas = ref([])
  const selected_table = ref("")
  const selected_columns = ref([])
  const SQL = ref('')

  const get_tables_info =()=> {
    axios.post(`${apiBaseUrl}/admin/get_total_tables`,
        {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          })
        .then((response) => {
          tables_columns.value = response.data.data
          tables.value = Object.keys(tables_columns.value)
        })
        .catch((error)=>{
          Snackbar({
            content: error.response.data.detail || error,
            type: "error"
          });
          router.push("/")
        })
  }
  const get_data_from_table =()=> {
    const formData = new FormData();
    formData.append('table_name', selected_table.value);
    axios.post(`${apiBaseUrl}/admin/get_data_from_table`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        .then((response)=>{
          datas.value = response.data.data;
          selected_columns.value = tables_columns.value[selected_table.value];
        })
        .catch ((error) => {
          Snackbar({
            content: error.response.data.detail || error,
            type: "error"
          });
        });
  }
  const execute_SQL =()=>{
    const formData = new FormData();
    formData.append("sql", SQL.value)
    axios.post(`${apiBaseUrl}/admin/execute_sql`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        .then((response)=>{
          Snackbar({
            content: response.data.message,
            type: "success"
          });
        })
        .catch ((error) => {
          Snackbar({
            content: error.response.data.detail || error,
            type: "error"
          });
        });
  }

  onBeforeMount(() => {
    get_tables_info();
  })
  onMounted(async () => {
    if (!token) {
      await router.push('/');
      return;
    }
    get_pending_comments();
  })
</script>

<template>
  <fieldset>
    <h1>数据库操作台</h1>
    <div class="row">
    <!--   表选择器   -->
      <select class="col-2" v-model="selected_table" @change="get_data_from_table">
        <option :value="tableName" v-for="tableName in tables" :key="tableName">{{ tableName }}</option>
      </select>
      <!--   列选择器     -->
      <div class="dropdown col-2 row row-cols-auto align-content-around">
        <button class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false" data-bs-auto-close="outside">
          选择项
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li v-for="column in tables_columns[selected_table]" :key="column" class="dropdown-item">
            <label :for="column" class="w-100 h-100">
              <input type="checkbox"
                  :value="column"
                  v-model="selected_columns"
                  :id="column">
              {{ column }}
            </label>
          </li>
        </ul>
      </div>

      <input type="text" placeholder="SQL语句" class="col" v-model.trim="SQL">
      <button class="col-1 nes-btn is-warning" :disabled="!SQL" @click="execute_SQL">执行SQL</button>
    </div>
    <!--   数据展示     -->
    <table class="table table-striped table-hover">
      <!--     头     -->
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col" v-for="column in selected_columns" :key="column">{{ column }}</th>
        </tr>
      </thead>
      <!--     数据     -->
      <tbody class="row-col-12">
        <tr v-for="(row, index) in datas" :key="index" class="col">
          <th scope="row" class="col">
            {{ index }}
          </th>
          <td v-for="column in selected_columns" :key="column" class="col">{{ row[column] }}</td>
        </tr>
      </tbody>
    </table>
  </fieldset>

  <div class="pending-comment">
      <h1>评论审核台</h1>
      <fieldset :disabled="pending_comment_cooldown || selected_pending_id_list.length < 1" class="action">
        <button :class="['nes-btn is-success', {'is-disabled': pending_comment_cooldown || selected_pending_id_list.length < 1}]" v-on:click="solve_pending_comments('passing_pending_comments')">通过</button>
        <button :class="['nes-btn is-error', {'is-disabled': pending_comment_cooldown || selected_pending_id_list.length < 1}]" @click="solve_pending_comments('del_pending_comments')">删除</button>
      </fieldset>
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">ip</th>
            <th scope="col">昵称</th>
            <th scope="col">邮箱</th>
            <th scope="col">所属页面</th>
            <th scope="col">链接</th>
            <th scope="col">日期</th>
            <th scope="col">评论</th>
            <th scope="col">顶层评论</th>
            <th scope="col">回复的评论</th>
            <th scope="col">@</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(comment, index) in pending_comment_list" :key="index">
            <th scope="row"><input class="form-check-input" type="checkbox"
                                   :value="comment.id"
                                   v-model="selected_pending_id_list"/>{{ comment.id }}</th>
            <td>{{ comment.ip }}</td>
            <td>{{ comment.userName }}</td>
            <td>{{ comment.email }}</td>
            <td>{{ comment.page }}</td>
            <td>{{ comment.link }}</td>
            <td>{{ comment.datetime }}</td>
            <td>{{ comment.comment_text }}</td>
            <td>{{ comment.parent_comment_id }}</td>
            <td>{{ comment.reply_comment_id }}</td>
            <td>{{ comment.replyUserId }}</td>
          </tr>
        </tbody>
      </table>
    </div>
</template>

<style scoped>
  .pending-comment{
    padding-bottom: 10px;
  }
</style>