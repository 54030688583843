<script setup>
  import HeaderHobby from "@/components/HeaderHobby.vue";
  import axios from "axios";
  import {apiBaseUrl} from "@/config";
  import {onMounted, ref} from "vue";
  import { ImagePreview } from '@varlet/ui'

  const meme_list = ref([])

  function get_memes(){
    axios.get(`${apiBaseUrl}/meme/get_memes`)
        .then((response)=>{
          meme_list.value = response.data.data
        }).catch((error)=>{
          alert(error.response.data.detail)
        })
  }

  onMounted(()=>{
    get_memes();
  })

</script>

<template>
  <HeaderHobby/>
  <main>
    <div class="container-xl" style="padding-top: 10px">
      <div class="card-columns">
        <div class="card card-pin" v-for="(meme, index) in meme_list" :key="index">
          <img class="card-img" v-lazy="'/get_img/meme/' + meme" :alt="'N_' + meme" @click="ImagePreview(`/get_img/meme/${meme}`)">
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
  .card-columns{
    -webkit-column-width: 8rem;
    column-count: 5;
    -webkit-column-gap: 1.25rem;
    column-gap: 1rem;
    margin-top: 3rem;
  }
  .card-columns .card{
    margin-bottom: 10px;
    border: 0;
  }
</style>