<script setup>
  import Vue3EmojiPicker from "vue3-emoji-picker";
  import axios from "axios";
  import {apiBaseUrl} from "@/config";
  import {Snackbar} from "@varlet/ui";
  import {defineProps, onMounted, ref} from "vue";
  import Cookies from "js-cookie";
  import {common_upload_img} from "@/common-api";
  const token = Cookies.get('N_token');
  const formData = ref({
    date: '',
    sign: '😊',
    content: '',
    id: null,
    imgs: null,
  })
  const props = defineProps({
    moment_id: {}
  })
  const form = ref()
  const var_uploader_config = ref({
    maxsize: 2097152,  // 单个文件最大数
    maxlength: 3,  // 文件数量
    multiple: true  // 是否允许多选
  })
  const imgs = ref([])
  const if_show_emoji = ref(false)
  const if_show_datePicker = ref(false)
  const handleEmojiSelect = (emoji)=>{
  if_show_emoji.value=false
  formData.value.sign = emoji.i;
}

  const handleBeforeFilter = (files) =>{
    const result = files.filter(file => file.name.endsWith('jpg') || file.name.endsWith('gif'))
    if (result.length !== files.length) Snackbar({
      type: 'error',
      content: '图片仅支持jpg、gif'
    })
    return result
  }
  const handleAfterRead = (file) => {
    file.state = 'loading'
    common_upload_img(file.file, 'moment')
        .then((response)=>{
          file.url = '/get_img/moment/' + response.data.file_name;
          file.cover = '/get_img/moment/' + response.data.file_name;
          file.state = 'success'
        })
        .catch(error=>{
          Snackbar({
            content: error.response.data.detail || error,
            type: "error"
          });
          file.state = 'error'
        })
  }
  const handleRemove = (file) =>{
    if (file.state !== 'success') return
    Snackbar({
      type: 'warning',
      content: '已删除图片'
    })
  }
  const handleSummit = ()=>{
    let action;
    action = formData.value.id ? 'update_moment': 'submit_moment';
    formData.value.imgs = imgs.value.map(item => item.name).toString();
    form.value.validate().then((result)=> {
    if (!result) return
    axios.post(`${apiBaseUrl}/admin/${action}`, formData.value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response)=>{
          Snackbar({
            content: response.data.message,
            type: "success"
          })
    })
        .catch((error)=>{
          Snackbar({
            content: error.response.data.detail || error,
            type: "error"
          })
        })
  })
  }
  onMounted(()=>{
    if (props.moment_id) {
      axios.get(`${apiBaseUrl}/moment/${props.moment_id}`)
          .then((response)=>{
            formData.value=response.data.data;
            imgs.value = formData.value.imgs.split(',').map(file_name => ({
                  url: '/get_img/moment/' + file_name,
                  cover: '/get_img/moment/' + file_name,
                  name: file_name,
                  state: 'success'
                })
            );
          })
          .catch((error)=>{
        Snackbar({
          type: 'error',
          content: error.response.data.detail
        })
      })
    }
  })
</script>

<template>
  <var-form ref="form">
    <var-row>
      <var-col :span="5">
        <var-input v-model="formData.id" placeholder="ID" disabled/>
      </var-col>
      <var-col :span="5" class="position-relative">
        <var-input @click='if_show_emoji=!if_show_emoji'
                   v-model="formData.sign"
                   placeholder="今日标志" :rules="[v => !!v || '标志呢？']"
                   readonly>
        </var-input>
        <Vue3EmojiPicker @select="handleEmojiSelect" v-show="if_show_emoji" @mouseleave="if_show_emoji=false"
                                 native hide-search :static-texts="{skinTone: '肤色'}"
                                 style="position: absolute;top: 3.5rem;z-index: 1;"/>
      </var-col>

      <var-col :span="7" class="position-relative">
        <var-input v-model="formData.date"
                   @click="if_show_datePicker=!if_show_datePicker"
                   placeholder="选择日期" :rules="[v => !!v || '日期呢？']"
                   readonly>
        </var-input>
        <var-date-picker v-show="if_show_datePicker" @mouseleave="if_show_datePicker=false" class="position-absolute" style="top: 3.5rem;z-index: 1;"
          v-model="formData.date"
        />
      </var-col>

      <var-col :span="24">
        <var-input textarea class="w-100"
          placeholder="今天，想到什么话想说的呢？"
          :rules="[v => !!v || '今天没内容吗？']"
          v-model="formData.content"
        />
      </var-col>
      <!--  图片上传   -->
      <var-col :span="24">
        <var-uploader v-model="imgs" @after-read="handleAfterRead"
                      @before-filter="handleBeforeFilter"
                      @remove = 'handleRemove'
                      :maxsize=var_uploader_config.maxsize
                      :maxlength=var_uploader_config.maxlength
                      :multiple=var_uploader_config.multiple />
      </var-col>

      <var-col :span="3">
        <button class="nes-btn is-primary" @click="handleSummit">{{ formData.id ? '修改' : '新增'  }}</button>
      </var-col>
    </var-row>
  </var-form>
</template>

<style scoped>

</style>