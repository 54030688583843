<script setup>
import {computed, ref} from "vue";
  const DateOfCreation = new Date("2024-07-08 20:50:00")
  const NowDate = ref()

  const TheElapsedDuration = computed(()=>{
    let gapSeconds = Math.floor((NowDate.value - DateOfCreation) / 1000);
    const gepDays = Math.floor(gapSeconds / (24 * 60 * 60));
    gapSeconds -= gepDays * 24 * 60 * 60;
    const hours = Math.floor(gapSeconds / (60 * 60));
    gapSeconds -= hours * 60 * 60;
    const minutes = Math.floor(gapSeconds / 60);
    gapSeconds -= minutes * 60;

    return `${gepDays}天${hours}时${minutes}分${gapSeconds}秒`;
  })
  setInterval(() => {
    NowDate.value=new Date().getTime()
  }, 1000);

</script>

<template>
  <footer class="footer">
    <div class="container">
      <img src="@/assets/img/Norvend-A.png"
           alt="Norvend"
           class="w-25"
           style="border-bottom: 2px black solid;">
    </div>
    <h3>热 爱 绝 不 放 弃</h3>
    <div><a href="https://beian.miit.gov.cn/" target="_blank" class="beian">粤ICP备2024282827号-1</a></div>
    <div>
      🏠
      <span class="text-N-blue">小</span>
      <span class="text-N-orange">诺</span>
      <span class="text-N-pink">之</span>
      <span class="text-N-blue">家</span>
      已建立了{{ TheElapsedDuration }} 😜
    </div>
  </footer>
</template>

<style scoped>
  .footer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 3rem 0;
    text-align: center;
    position: relative;
    flex-direction: column;

    background-color: lightgrey;
    border-top: 4px dashed gray;
  }
  .footer *{
      font-family: PixelFont, serif;
      font-weight: bolder;
  }
  .footer > div{
     margin-bottom: .5rem;
  }
  .beian{
    margin-top: 1rem;
  }
  .beian:hover{
    color: dodgerblue;
  }
</style>