import {createRouter, createWebHistory} from "vue-router";
import AboutMePage from "@/views/AboutMePage.vue";
import GuestMessage from "@/views/GuestMessage.vue";
import ArticlePage from "@/views/ArticlePage.vue";
/*管理员专属页面*/
import AdminPage from "@/views/AdminPage.vue";
import DbManager from "@/components/Admin/subPage/DbManage.vue";
import TheArticlePage from "@/views/TheArticlePage.vue";

import ArticleEditor from "@/components/Admin/Article/ArticleEditor.vue";
import ArticleManage from "@/components/Admin/Article/ArticleManage.vue";
import MemesPage from "@/views/MemePage.vue";
import MomentsPage from "@/views/MomentPage.vue";
import MomentsManage from "@/components/Admin/Moment/MomentsManage.vue";
import UserManager from "@/components/Admin/User/UserManage.vue";
import IPManage from "@/components/Admin/IPRecord/IPManage.vue";
import MomentEditor from "@/components/Admin/Moment/MomentEditor.vue";
import UserEditor from "@/components/Admin/User/UserEditor.vue";
import MemeManage from "@/components/Admin/Meme/MemeManage.vue";

const routes = [
  {
    path: '/',
    component: AboutMePage
  },
  {
    path: '/guest/',
    component: GuestMessage
  },
  {
    path: "/article/",
    component: ArticlePage,
  },
  {
    path: "/meme/",
    component: MemesPage
  },
  {
    path: "/moment/",
    component: MomentsPage,
  },

  {
    path: '/N_admin/',
    component: AdminPage,
    children: [
      {
        path: 'db_manager/',  // 子页前不能加/
        component: DbManager
      },
      {
        path: 'article/',
        children: [
          {
            path: "manage/",
            component: ArticleManage
          },
          {
            path: ":article_id/",
            component: ArticleEditor,
            props: true
          },
          {
            path: "new",
            component: ArticleEditor,
          },
        ]
      },
      {
        path: 'moment/',  // 子页前不能加/
        children: [
          {
            path: 'manage/',
            component: MomentsManage,
          },
          {
            path: 'new/',
            component: MomentEditor,
          }
          ,
          {
            path: ':moment_id/',
            component: MomentEditor,
            props: true
          }
        ]
      },
      {
        path: 'user/',
        children: [
          {
            path: 'manage/',
            component: UserManager,
          },
          {
            path: ':user_id/',
            component: UserEditor,
            props: true
          }
        ]
      },
      {path: 'ip_record/', component: IPManage},
      {path: 'meme/', component: MemeManage}
    ]
  },

  //  单独动态路由
  {
    path: "/article/:article_id/",
    component: TheArticlePage,
    props: true
  }
];
const router = createRouter({
  history: createWebHistory(),
  routes,
})
export {routes, router};