<template>
<!--  <pre-loader :page_is_loaded="page_is_loaded"/>-->
  <pre-loader/>

  <router-view/>
  <footer-part/>
  <var-back-top :duration="300" title="我要上去看看"/>
  <NavigationMenu/>
</template>

<script setup>
  import PreLoader from "@/components/PreLoader.vue";
  import NavigationMenu from "@/components/NavigationMenu.vue";
  import FooterPart from "@/components/FooterPart.vue";
  import {defineComponent} from "vue";

  defineComponent({
    components: {NavigationMenu, PreLoader},
  })

</script>

<style>
  @import 'bootstrap/dist/css/bootstrap.min.css';
  @import "@/assets/plugin/NES/nes.min.css";
  @import "@/assets/css/default.css";
  @import "@/assets/css/N-icon.css";
</style>
