<script setup>
  import {onMounted, ref} from "vue";
  import axios from "axios";
  import {apiBaseUrl} from "@/config";
  const token = Cookies.get('N_token');
  import Cookies from "js-cookie";
  import {Snackbar} from "@varlet/ui";
  const article_table_data = ref()  // 文章表
  const del_article = (title, the_id)=>{
    const formData = new FormData()
    formData.append("del_id", the_id)
    if (confirm(`确定删除文章《${title}》吗？`)) {
      axios.post(`${apiBaseUrl}/admin/del_article`,
          formData,
          {
            headers: {Authorization: `Bearer ${token}`}
          })
          .then(()=>{
            fetch_articles()
          })
          .catch((error)=>{
            Snackbar({
            content: error.response.data.detail || error,
            type: "error"
          });
      })
    }
  }
  const fetch_articles = ()=>{
    axios.get(`${apiBaseUrl}/article/get_articles?` + new URLSearchParams({is_visible: false}),
        {
          headers: {Authorization: `Bearer ${token}`}
        }
    ).then((response)=>{
      article_table_data.value = response.data.data
    })
  }
  onMounted(()=>{
    fetch_articles()
  })
</script>

<template>
  <div class="action">
      <router-link class="nav-link dropdown-item" to="/N_admin/article/new" @click="show_base_page=!show_base_page">
        新增文章
      </router-link>
    </div>
  <var-table>
      <thead>
        <tr>
          <th scope="col">标题</th>
          <th scope="col">表述</th>
          <th scope="col">作者</th>
          <th scope="col">可见否</th>
          <th scope="col">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="article in article_table_data" :key="article.id">
          <th scope="row">{{ article.title }}</th>
          <td>{{ article.describe }}</td>
          <td>{{ article.author }}</td>
          <td><var-switch v-model="article.is_visible" disabled/></td>
          <td class="action">
            <router-link :to="'./' + article.id">编辑</router-link>
            <a @click="del_article(article.title, article.id) ">删除</a>
          </td>
        </tr>
      </tbody>
    </var-table>
</template>

<style scoped>

</style>