<script setup>
  import HeaderHobby from "@/components/HeaderHobby.vue";
  import NewArticles from "@/components/SideCardParts/NewArticles.vue";
  import MineInfo from "@/components/SideCardParts/MineInfo.vue";

  const mine_love = [
    {"src_part": '_minecraft_.jpg', "title": "Minecraft", "saying": "MC,是一种信仰！"},
    {"src_part": 'spyxfamily.jpg', "title": "SPY × FAMILY", "saying": "⌓‿⌓哇酷哇酷！"},
    {"src_part": '_tombraider_.jpg', "title": "TOMB RAIDER", "saying": "我不是什么超级英雄!"},
    {"src_part": '_tomandjerry_.jpg', "title": "TOM AND JERRY", "saying": "他叫莫卡努，是个机器猫~"},
    {"src_part": '_pixelart_.jpg', "title": "PIXEL ART", "saying": "像素即万物！"},
    {"src_part": '_selenagomez_.jpg', "title": "SELENA GOMEZ", "saying": "Like A Love Song"},
  ]

  const link_data = [
    {link:"https://www.kugou.com/", icon:"kugou", name: "酷狗音乐"},
    {link:"https://www.yuque.com/", icon:"yuque", name: "羽雀笔记"},
    {link:"https://gitee.com/", icon:"gitee", name: "Gitee"},
    {link:"https://www.bootcss.com/", icon:"bootstrap", name: "Bootstrap"},
    {link:"https://www.python.org/", icon:"python", name: "Python"},
    {link:"https://www.pythonanywhere.com/", icon:"pyAnywhere", name: "PythonAnywhere"},
    {link:"https://nostalgic-css.github.io/NES.css/", icon:"nes", name: "NES.css"},
    {link:"https://varletjs.org/#/zh-CN/", icon:"varlet", name: "VARLET UI"},
    {link:"https://code-farmer-i.github.io/vue-markdown-editor/zh/", icon:"vueMd", name: "v-md-editor"},
    {link:"https://hpgpixer.jp/", icon:"hpgpixer", name: "Pixel Garrow"},
    {link:"https://cn.vuejs.org/", icon:"vue3", name: "Vue.js"},
    {link:"https://www.aseprite.org/", icon:"aseprite", name: "Aseprite"},
    {link:"https://www.jetbrains.com/pycharm/", icon:"pycharm", name: "Pycharm"},
    {link:"https://cn.aliyun.com/", icon:"aliyun", name: "阿里云"},
    {link:"https://fastapi.tiangolo.com/zh/", icon:"fastApi", name: "FastAPI"},
    {link:"https://mobaxterm.mobatek.net/", icon:"Xterm", name: "MobaXterm"},
  ];
</script>

<template>
  <HeaderHobby/>
  <main>
    <div class='container-xxl bg-N-pink' style="height: 22px; border-radius: 11px"></div>
    <div class="row container-xl">
      <div class="main-contain col-md-12 col-lg"
           data-bs-spy="scroll"
           data-bs-target="#catalog-list"
           data-bs-offset="0"
           tabindex="0">
        <!--   自我介绍   -->
        <div id="item-1" class="recommendation">
          <h1 class="title"><span class="text-N-orange">关于小诺</span></h1>:-)
          <p class="avatar N-orange"></p>
          <div class="row">
            <div class="describe col">
              <h4 class="sub-info text-N-orange">名字</h4>
              <p>让我回忆回忆...<i class="N-icon yiwen"/>，起初是叫 <span class="text-N-orange">诺凡德</span>，已经忘了是何时起的名字，只记得是在一篇小说，
                或是散文的主人公名字得到得灵感。英文名就叫他"<span class="text-N-blue">Norvend</span>"吧！但是英文名主要是注册一些账号的时候很
                方便，更喜欢的是小名 —— <span class="text-N-pink">小诺 </span><i class="N-icon keai"/>~！看大多数人物小名都带"小"字，很亲切——像什么小六子，小福贵
                <span class="text-decoration-line-through">、小王八!</span> <i class="N-icon xiaoku"/>... :-)</p>
              <h4 class="sub-info text-N-blue">境况</h4>
              <p>
                再来讲讲境况吧！base广州，留在这的原因其实就几点...<span class="text-decoration-line-through">（←想想也就这几点）</span>。
                身在异乡，就不得不提一下俺的故乡，那是一个近<span class="text-N-blue">海</span>的小镇，最爱的便是去<span class="text-N-blue">海</span>边
                沙滩了，想起来那时候的海真的是<span class="text-N-blue">蓝色</span>的！真的是<span class="text-N-blue">蓝色</span>的！
                真的是... 好吧，话说回来，在广州，算起来总共也有5年了（阿伟："5年！5年！你知道我这5年是怎么过的吗？你知道吗?!<i class="N-icon nu"/>"），
                还算是有点点进步的，——指前进的步伐变大了！<span class="text-decoration-line-through">只为赶上上班的那班公交</span>
                <i class="N-icon daku"/>。
              </p>
              <h4 class="sub-info text-N-pink">哈哈哈</h4>
              <p>
                最后就是总结（凑数），展望（画饼）环节！做这个个人网站，纯属个人爱好吧！没想要有什么具体的功能或者作用，没什么太大的要求，就是希望自己一直
                以来想实现的东西能确切落实下来，能够具象化自己一直想要的，一个属于自己的博客，以及个人的设计和创意（虽然说已经拖了近
                <span class="text-N-orange">3年</span>了）。还是很开心的<i class="N-icon keai"/>，现在感觉差不多了，又想不到要写什么，那就
                当个人想法展示台了吧。（是 <span class="text-decoration-line-through">骡子</span> 是 马 该拉出来溜溜了:-)）。好了，就说到这里吧！
                继续期待 <i class="N-icon ku"/>~
              </p>
            </div>
          </div>
        </div>
        <!--   我的爱好   -->
        <div id="item-2" class="recommendation">
          <h1 class="title"><span class="text-N-blue">喜好</span></h1>qwq
          <div class="row">
              <div class="left-item col-4 d-flex flex-column align-items-end">
                <div class="nes-balloon from-right align-content-center">
                  <span style="color: whitesmoke">我的世界</span>
                </div>
                <div class="nes-balloon from-right align-content-center">
                  <span style="color: whitesmoke">古墓丽影</span>
                </div>
                <div class="nes-balloon from-right align-content-center">
                  <span style="color: whitesmoke">像素艺术</span>
                </div>
              </div>

              <div class="mine-center col text-center">
                <p class="avatar N-blue"></p>
              </div>

              <div class="right-item col-4 d-flex flex-column">
                <div class="nes-balloon from-left align-content-center">
                  <span style="color: whitesmoke">间谍过家家</span>
                </div>
                <div class="nes-balloon from-left align-content-center">
                  <span style="color: whitesmoke">猫和老鼠</span>
                </div>
                <div class="nes-balloon from-left align-content-center">
                  <span style="color: whitesmoke">赛琳娜·戈麦斯</span>
                </div>
              </div>
            </div>
          <div class="item-show container-fluid">
            <ul class="row row-cols-lg-3 row-cols-md-2">
              <li class="card col" v-for="item in mine_love" :key="item.title">
                <div class="position-relative overflow-hidden">
                  <div class="mask">
                    <h3 class="position-absolute say-something">{{ item.saying }}</h3>
                  </div>
                  <img v-lazy="require('@/assets/img/HeaderHobby/' + item.src_part)" class="card-img-top" :alt="item.title">
                </div>
                <div class="card-body">
                  <h3 class="card-text">{{ item.title }}</h3>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!--   工具   -->
        <div id="item-3" class="recommendation tool-show">
          <h1 class="title"><span class="text-N-orange">工具</span></h1>:-\
          <p class="text-center">这些是常用工具，工作、学习、生活等等。图标都是个人模仿制作的。 |;-]</p>
          <div class="conveying-belt row row-cols-auto
          justify-content-start">
            <a v-for="{link, icon, name} in link_data"
               :key="icon"
               :href="link"
               class="col"
               style="margin: 1rem 1rem 0 0"
               target="_blank">
              <span class="name text-truncate">
                <i :class="['N-icon', icon]"></i>
                <span style="font-size: 1.7rem">{{ name }}</span>
              </span>
            </a>
          </div>
        </div>

        <div id="item-4" class="recommendation">
          <h1 class="title"><span class="text-N-pink">学习历程</span></h1>/0.0\
          <ol class="map-contain">
            <li class="process-node">
              <div class="message-box">
                <h3 class="message-title">初识Pythonanywhere</h3>
                <time><i class="N-icon date little"></i>202x/xx/xx</time>
                <p>从同学那了解到了Pythonanywhere平台，开始了解Web项目，自然而然地想自己做一个属于自己的个人页面。原来那就叫个人博客啊:-o</p>
              </div>
            </li>
            <li class="process-node">
              <div class="message-box">
                <h3 class="message-title">初识云服务器</h3>
                <time><i class="N-icon date little"></i>2022/09/xx</time>
                <p>实习期间了解到云服务器，可以部署个人应用，其中就包括博客。想去搞一个，但是很纠结，然后就又推延了... ...</p>
              </div>
            </li>
            <li class="process-node">
              <div class="message-box">
                <h3 class="message-title">还是个Web萌新</h3>
                <time><i class="N-icon date little"></i>202x ~ 至今</time>
                <p>从接触pythonanywhere开始，都在尝试个人网页制作。既想要有个人创意，但创意又十分有限，中间改了很多版页面，都...一言难尽。
                  而且页面的制作还只停留在用原生技术层面。:-\</p>
              </div>
            </li>
            <li class="process-node">
              <div class="message-box">
                <h3 class="message-title">原来是要这么搭建</h3>
                <time><i class="N-icon date little"></i>2024/06/30</time>
                <p>心血来潮，先放下个人创意，去看看别人的创意，模仿，然后再结合自己的创意，想想这样子应该才是正道。然后也一鼓作气，买了
                  阿里云服务器、域名，都整起来！ 顺着还想着用前端框架Vue，也是经过了解才知道怎么完整地做项目。终于好起来了:-)</p>
              </div>
            </li>
            <li class="process-node">
              <div class="message-box">
                <h3 class="message-title">模仿是进步地捷径</h3>
                <time><i class="N-icon date little"></i>2024/07/11</time>
                <p>找到了一个不错的模板，花了10米！拿到的是打包后的文件...不过好在还有基础，用Vue一步一步实现，调整一些样式什么的，一步一步完善，直到现在... :-)))</p>
              </div>
            </li>
          </ol>
        </div>
      </div>

      <!--  侧边栏  -->
      <div class="side-guider col-4 d-none d-lg-block" id="side-guider">
        <MineInfo/>
        <NewArticles/>

        <div class="side-sticky text-center">
          <h1>本页目录</h1>
          <ol class="catalog-list" id="catalog-list">
            <li>
              <a href="#item-1" class="catalog-item"><i class="N-icon ku"/>关于我</a>
            </li>
            <li>
              <a href="#item-2" class="catalog-item"><i class="N-icon ai"/>喜好</a>
            </li>
            <li>
              <a href="#item-3" class="catalog-item"><i class="N-icon website"/>工具</a>
            </li>
            <li>
              <a href="#item-4" class="catalog-item"><i class="N-icon diary"/>学习历程</a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
  .main-contain{
    position: relative;
  }
  .main-contain *{
    font-family: PixelFont, serif;
    font-weight: bolder;
  }
  .main-contain .recommendation:not(:first-child){
    padding: 40px 0 40px;
  }

  .sub-info{
    margin-top: 2rem;
  }
  .recommendation p{
    //font-size: 1.2rem;
    text-indent: 2.4rem;
  }

  .mine-hobby .name>img{
    position: relative;
    left: 3.2rem;
    top: 0.26rem;
    mix-blend-mode: multiply;
  }

  .recom-summary .mine-center{
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
  }
  .nes-balloon{
    width: max-content;
    border-image-repeat: stretch;
    line-height: 1.5rem;
    font-size: 2rem;
  }

  .left-item > :nth-child(2n-1),
  .left-item > :nth-child(2n-1)::before,
  .left-item > :nth-child(2n-1)::after,
  .right-item > :nth-child(2n)::before,
  .right-item > :nth-child(2n)::after,
  .right-item > :nth-child(2n){
    background-color: dodgerblue;
  }

  .left-item > :nth-child(2n),
  .left-item > :nth-child(2n)::before,
  .left-item > :nth-child(2n)::after,
  .right-item > :nth-child(2n-1)::before,
  .right-item > :nth-child(2n-1)::after,
  .right-item > :nth-child(2n-1){
    background-color: darkorange;
  }

  .left-item > :nth-child(2n-1){
    transform: translateX(2rem);
  }
  .right-item > :nth-child(2n-1){
    transform: translateX(-2rem);
  }

  .item-show{
    margin-top: 2rem;
  }
  .item-show li{
    background-color: inherit;
  }

  .mask{
    height: 0;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
    border-radius: 0 0 25% 25%;
  }

  .mask{
    transition: all 0.5s ease;
  }

  .say-something{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    transition-delay: 0.2s;
  }

  .item-show li:hover{
    .mask{
      height: 100%;
      border-radius: 0;
    }
    .card-img-top{
      filter: blur(5px);
    }
    .say-something{
      color: white;
      bottom: 50%;
      z-index: 2;
    }
  }

  .card{
    margin-bottom: 2.5rem;
    border-width: 0;

  }

  .tool-show{
    padding: 2rem 0;
  }

  .process-node{
    border-top: 3px dotted dodgerblue;
    position: relative;
    margin: 0 10rem;
  }

  .process-node::before {
    content: '';
    position: absolute;
    border: 2px dotted dodgerblue;
    top: -8px;
    left: -6px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: darkorange;
  }

  .message-box{
    padding: 6px 0 22px 22px;
    border-left: hotpink 2px dotted;
  }
  .process-node:nth-child(2n) .message-box{
    bottom: 0;
  }

  .catalog-list{
    font-size: 1.4rem;
  }
  .catalog-list li{
    padding: 10px 0;
  }

  /* 位置偏移了，不知为什么*/
  .catalog-list .N-icon{
    text-indent: -16px;
  }

  .catalog-item{
    position: relative;
  }
  .catalog-item::before{
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    background-color: dodgerblue;
    z-index: -1;
    transition: all .5s ease;
  }
  .catalog-item.active::before{
      width: 100%;
    }

  @media (min-width: 768px) and (orientation: portrait) {
    p {
      font-size: 1.5rem;
    }
  }
</style>